<template>
  <el-container v-if="info.id">
    <el-main class="left">
      <div class="order-info">
        <div class="title">订单信息</div>
        <div class="content">
          <ul class="order-num">
            <li>
              订单编号：
              <span>{{ info.order_sn }}</span>
            </li>
          </ul>
          <div class="handle-progress" v-if="progress_status1">
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff">1</div>
              <p class="handle-text" style="color: #409eff">提交时间</p>
              <p class="handle-time">{{ getDateformat(info.create_time) }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff">2</div>
              <p class="handle-text" style="color: #409eff">订单关闭</p>
              <p class="handle-time">{{ getDateformat(info.cancel_time) }}</p>
            </div>
          </div>
          <div class="handle-progress" v-if="progress_status2">
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff">1</div>
              <p class="handle-text" style="color: #409eff">提交订单</p>
              <p class="handle-time">{{ getDateformat(info.create_time) }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff">2</div>
              <p class="handle-text" style="color: #409eff">付款时间</p>
              <p class="handle-time">{{ getDateformat(info.pay_time) }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff">3</div>
              <p class="handle-text" style="color: #409eff">订单完成</p>
              <p class="handle-time">{{ getDateformat(info.finish_time) }}</p>
            </div>
          </div>
          <div class="handle-progress" v-if="progress_status3">
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff">1</div>
              <p class="handle-text" style="color: #409eff">提交订单</p>
              <p class="handle-time">{{ getDateformat(info.create_time) }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff">2</div>
              <p class="handle-text" style="color: #409eff">付款时间</p>
              <p class="handle-time">{{ getDateformat(info.pay_time) }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff">3</div>
              <p class="handle-text" style="color: #409eff">商家呼叫骑手时间</p>
              <p class="handle-time">{{ getDateformat(info.call_time) }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff">4</div>
              <p class="handle-text" style="color: #409eff">骑手接单时间</p>
              <p class="handle-time">{{ getDateformat(info.take_time) }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff">5</div>
              <p class="handle-text" style="color: #409eff">订单完成</p>
              <p class="handle-time">{{ getDateformat(info.finish_time) }}</p>
            </div>
          </div>
          <div class="handle-progress" v-if="!progress_status1 && !progress_status2 && !progress_status3">
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff">1</div>
              <p class="handle-text" style="color: #409eff">提交订单</p>
              <p class="handle-time" v-if="info.create_time">{{ getDateformat(info.create_time) }}</p>
            </div>
            <div class="line"></div>
            <div class="item" :style="{ background: info.pay_time ? '#ddddfd' : '' }">
              <div class="num" :style="{ background: info.pay_time ? '#409EFF' : '', color: info.pay_time ? '#fff' : '' }">2</div>
              <p class="handle-text" :style="{ color: info.pay_time ? '#409EFF' : '' }">付款时间</p>
              <p class="handle-time" v-if="info.pay_time">{{ getDateformat(info.pay_time) }}</p>
            </div>
            <div class="line"></div>
            <div class="item" :style="{ background: info.call_time ? '#ddddfd' : '' }">
              <div class="num" :style="{ background: info.call_time ? '#409EFF' : '', color: info.call_time ? '#fff' : '' }">4</div>
              <p class="handle-text" :style="{ color: info.call_time ? '#409EFF' : '' }">商家呼叫骑手时间</p>
              <p class="handle-time" v-if="info.call_time">{{ getDateformat(info.call_time) }}</p>
            </div>
            <div class="line"></div>
            <div class="item" :style="{ background: info.take_time ? '#ddddfd' : '' }">
              <div class="num" :style="{ background: info.take_time ? '#409EFF' : '', color: info.take_time ? '#fff' : '' }">4</div>
              <p class="handle-text" :style="{ color: info.take_time ? '#409EFF' : '' }">骑手接单时间</p>
              <p class="handle-time" v-if="info.take_time">{{ getDateformat(info.take_time) }}</p>
            </div>
            <div class="line"></div>
            <div class="item" :style="{ background: info.finish_time ? '#ddddfd' : '' }">
              <div class="num" :style="{ background: info.finish_time ? '#409EFF' : '', color: info.finish_time ? '#fff' : '' }">5</div>
              <p class="handle-text" :style="{ color: info.finish_time ? '#409EFF' : '' }">订单完成</p>
              <p class="handle-time" v-if="info.finish_time">{{ getDateformat(info.finish_time) }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="buyers-info">
        <div class="title">买家信息</div>
        <div class="content">
          <div>
            <p>
              下单人：
              <span>{{ info.address_info_d.name || '--' }}</span>
            </p>
            <p>
              下单人电话：
              <span>{{ info.address_info_d.mobile || '--' }}</span>
            </p>
            <p>预约时间：{{ info.delivery_start == 0 ? '立即配送' : getDateformat(info.delivery_start) + '-' + getDateformat(info.delivery_end) }}</p>
            <p>
              配送地址：
              <span>{{ info.address_info_d.address || '--' }}{{ info.address_info_d.address_name || '--' }}{{ info.address_info_d.door_no || '--' }}</span>
              <span v-if="info.rider_distance">{{ info.rider_distance }}</span>
            </p>
            <p>买家备注：{{ info.remark || '--' }}</p>
          </div>
        </div>
      </div>
      <div class="buyers-info" v-if="info.rider.data_d.sender_name">
        <div class="title">骑手信息</div>
        <div class="content">
          <div>
            <p>
              骑手：
              <span>{{ info.rider.data_d.sender_name || '--' }}</span>
            </p>
            <p>
              骑手电话：
              <span>{{ info.deliver.sender_mobile || '--' }}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="buyers-info" v-if="info.cancel_time">
        <div class="title">取消原因</div>
        <div class="content">
          <div>
            <p>无取消原因</p>
          </div>
        </div>
      </div>
      <div class="goods-info">
        <div class="title">菜品信息</div>
        <div class="content">
          <el-table :data="info.ordergoods" :header-cell-style="{ 'background-color': '#F8F9FA' }">
            <el-table-column prop="goods_img" label="商品信息" align="center" width="300px">
              <template slot-scope="scope">
                <div class="goods-title">
                  <el-image :src="scope.row.cuisine_picture"></el-image>
                  <p>
                    {{ scope.row.cuisine_name }}
                  </p>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="sku_list.title" label="规格" align="center">
              <template slot-scope="scope">
                {{ scope.row.sku_name ? scope.row.sku_name : '- -' }}
              </template>
            </el-table-column>
            <el-table-column prop="goods_price" label="单价" align="center"></el-table-column>
            <el-table-column prop="goods_num" label="数量" align="center"></el-table-column>
            <el-table-column prop="goods_pay_price" label="小计" align="center"></el-table-column>
          </el-table>
          <div class="price">
            <p>
              <span>菜品总价：</span>
              <span>￥{{ info.goods_total_price }}</span>
            </p>
            <p v-if="info.table_fee * 1">
              <span>餐位费用：</span>
              <span>￥{{ info.table_fee }}*{{ info.table_no }}={{ info.table_fee * 1 * info.table_no * 1 }}</span>
            </p>
            <p v-if="info.postage_fee">
              <span>打包费用：</span>
              <span>￥{{ info.pack_fee ? info.pack_fee : 0 }}</span>
            </p>
            <p v-if="info.pack_fee">
              <span>配送费用：</span>
              <span>￥{{ info.postage_fee ? info.postage_fee : 0 }}</span>
            </p>
            <p v-if="info.reduce_price * 1">
              <span>优惠券立减：</span>
              <span>￥{{ info.reduce_price }}</span>
            </p>
          </div>
          <div class="total-price">
            <span>实付：</span>
            <span>￥{{ Number(info.total_price) > 0 ? Number(info.total_price) : Number(info.price) }}</span>
          </div>
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import { getDateformat } from '@/util/getDate';
export default {
  data() {
    return {
      id: '',
      info: {},
      goods_info: [],
      logisticsInfo: '',
      company_name: '',
      tracking_no1: '',
      orderSendType: 1,
      progress_status1: 0,
      progress_status2: 0,
      progress_status3: 0,

      forminfo: null,
    };
  },
  created() {
    let utils = this.$store.state.utils;
    utils.page = Number(this.$route.query.page);
    utils.rows = Number(this.$route.query.rows);
    utils.is_record = !0;
    this.id = this.$route.query.id;
    this.getOrderInfo();
  },
  methods: {
    handleSales(val) {
      this.handle_state = val;
      this.showHandleSales = !0;
    },
    getOrderInfo() {
      this.$axios.post(this.$api.catering.orderDetail, { id: this.id }).then((res) => {
        if (res.code == 0) {
          let info = res.result;
          let stateArr = [10];
          if (info.pay_time) stateArr.push(20);
          if (info.cancel_time) stateArr.push(30);
          if (info.finish_time) stateArr.push(40);
          if (info.type == 3) stateArr.push(50);
          // 订单关闭
          if ([10, 30].sort().toString() == stateArr.sort().toString()) this.progress_status1 = 1;
          // 完成订单
          if ([10, 20, 40].sort().toString() == stateArr.sort().toString()) this.progress_status2 = 1;
          // 配送
          if ([10, 20, 40, 50].sort().toString() == stateArr.sort().toString()) this.progress_status3 = 1;
          console.log(stateArr);
          this.info = info;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getDateformat(val) {
      return getDateformat(val);
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  font-family: Microsoft YaHei;
  font-size: 14px;
  color: #17171a;
}
.title {
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 20px;
}
.left {
  margin-right: 8px;
  background: #fff;
  .order-info {
    margin-bottom: 30px;
  }
  .order-info .content {
    .order-num {
      display: flex;
      height: 47px;
      background: #f0f0f5;
      align-items: center;
      padding-left: 24px;
      li {
        font-size: 14px;
        margin-right: 40px;
        span {
          font-weight: 400;
          color: #17171a;
        }
      }
    }
    .handle-progress {
      display: flex;
      justify-content: center;
      height: 190px;
      align-items: center;
      .line {
        height: 4px;
        width: 120px;
        background: #f0f0f5;
      }

      .item {
        background: #fff;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .num {
          width: 32px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          background: #c8c8cc;
          border-radius: 50%;
          color: #ffffff;
          font-size: 14px;
          font-weight: bold;
        }
        .handle-text {
          width: 100px;
          text-align: center;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: -25px;
          font-size: 14px;
          font-weight: bold;
          color: #969699;
        }
        .handle-time {
          width: 120px;
          position: absolute;
          left: 50%;
          text-align: center;
          transform: translateX(-50%);
          bottom: -25px;
          font-size: 12px;
          font-weight: 400;
          color: #969699;
        }
      }
    }
    .handle-status {
      padding: 30px 0;
      background: #f0f0f5;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .status {
        font-size: 16px;
        font-weight: bold;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: #969699;
      }
      .btn {
        margin-top: 18px;
      }
    }
  }
  .buyers-info {
    margin-bottom: 30px;
    .content {
      margin-top: 24px;
      padding-left: 40px;
      div {
        display: flex;
        span {
          font-weight: 400;
        }
        p {
          width: 240px;
          &:last-child {
            width: auto;
          }
        }
        &:nth-child(1),
        &:nth-child(2) {
          margin-bottom: 8px;
        }
      }
    }
  }
  .goods-info .content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .price {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 100%;
      margin-top: 20px;
      border-bottom: 1px solid #f5f5fa;
      color: #969699;
      span:last-child {
        color: #17171a;
        width: 80px;
        text-align: right;
      }
      p {
        display: flex;
        margin-bottom: 5px;
      }
    }
    .goods-title {
      display: flex;
      justify-content: center;
      align-items: center;
      .el-image {
        width: 68px;
        height: 68px;
        border-radius: 8px;
        margin-right: 8px;
      }
      p {
        flex: 1;
      }
    }
    .total-price {
      font-weight: bold;
      margin-top: 5px;
      display: flex;
      span:last-child {
        text-align: right;
        font-weight: 400;
        color: #fd5e94;
        width: 80px;
      }
    }
  }
}

.logistics-address {
  width: 480px;
  background: #fff;
  overflow: hidden;
  .sn {
    margin-bottom: 30px;
  }
  /deep/ .el-timeline-item__content {
    color: #17171a;
  }
}

.formdata {
  .row {
    display: flex;
    line-height: 36px;
    .row_left {
      width: 160px;
      text-align: right;
      flex-shrink: 0;
    }
    .row_right {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      .el-image {
        height: 76px;
        width: auto;
        margin-bottom: 10px;
        margin-right: 10px;
      }
    }
  }
}
</style>
